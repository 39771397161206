import React, { useState } from 'react';
import { Link } from 'gatsby';
import Button from '../atoms/inputs/Button';
import { useLocation } from '@reach/router';

export default function BusinessApplicant() {
  const { pathname } = useLocation();
  const [name, setName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [email, setEmail] = useState('');
  // const [url, setUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // const [phone, setPhone] = useState('');


  const inputStyles =
    'block w-full px-4 py-3 h-12 appearance-none rounded-3xl border-0 text-base bg-white text-grey-25 placeholder-grey-25 focus:outline-none focus:ring-1 focus:ring-highlight shadow-md';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!name || !businessName || !email) {
      return setErrorMessage('Please fill in all required fields');
    }

    try {
      await fetch('/.netlify/functions/business-applicant', {
        method: 'POST',
        body: JSON.stringify({
          name,
          businessName,
          email,
        }),
      })
        .then((res) => {
          if (res.status === 200) {
            setName('');
            setBusinessName('');
            setEmail('');
            // setUrl('');
            // setPhone('');
            setErrorMessage('Thank you for your submission');
            setIsLoading(false);
          }

          if (res.status === 500) {
            setErrorMessage('This email has already been entered');
            setIsLoading(false);
          }
          return res.json();
        })
        .catch((err) => console.log(err.message));
    } catch (error) {
      console.error(error);
      setErrorMessage('An error occurred. Please try again later.');
      setIsLoading(false);
    }
  };

  return (
    <div
      id="business-applicant-sign-up-form" 
      className="relative z-10 w-full"
    >
      <form onSubmit={handleSubmit} className="w-full">
        <div className="flex flex-col gap-4">
          <div className="grid grid-cols-2 gap-4 w-full">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className={`${inputStyles} h-[42px]`}
              placeholder="Name"
            />

            <input
              type="text"
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
              required
              className={`${inputStyles} h-[42px]`}
              placeholder="Business Name"
            />
          </div>

          <div className="grid grid-cols-2 gap-4 w-full">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className={`${inputStyles} `}
              placeholder="Email"
            />

            <Button
              type="Request Access"
              label="Request Access"
              className="w-full !bg-primary hover:!bg-primary/90 !from-primary !to-primary transition-colors !px-12 !py-2"
              isLoading={isLoading}
              id="business-applicant-button"
            />
          </div>
        </div>

        {errorMessage && (
          <p className="mt-4 text-default text-xl">
            {errorMessage}
          </p>
        )}
      </form>
    </div>
  );
}
