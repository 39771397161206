import React from 'react';
import LoadingSpinner from '../animations/LoadingSpinner';

export default function Button({
    label,
    className,
    buttonClasses = '',
    onClickHandler,
    type,
    id,
    isSecondaryButton,
    isKokoButton,
    isLoading = false,
    minWidth,
}) {
    const fontSize = 'text-text-base md:text-base lg:text-lg';
    const textProperties = `${!isSecondaryButton ? 'text-white' : 'text-purple-700'} text-center`;
    const animationProperties = 'transition-all duration-200 ease-linear';
    const padding = 'py-[12px] px-6 md:px-8 lg:px-10 xl:px-8';
    const borderRadius = 'rounded-[72.5px]';
    
    // Define cursor style
    const cursorStyle = 'cursor-pointer';
    
    // Define border styles - completely remove border and outline for Koko buttons
    let border = '';
    if (isSecondaryButton) {
        border = 'border border-primary focus:outline-none';
    } else if (isKokoButton) {
        border = 'border-0 focus:outline-none focus:ring-0';
    } else {
        border = 'border border-primary focus:outline-none';
    }
    
    // Define background styles
    let backgroundGradient = '';
    if (isKokoButton) {
        // Use a special background style for Koko with smooth transition
        backgroundGradient = 'relative bg-gradient-to-r from-koko-brand to-koko-accent before:absolute before:inset-0 before:bg-gradient-to-r before:from-koko-accent before:to-koko-brand before:opacity-0 before:transition-opacity before:duration-300 before:ease-in-out hover:before:opacity-100 before:rounded-[72.5px] overflow-hidden';
    } else if (isSecondaryButton) {
        backgroundGradient = 'bg-white';
    } else {
        backgroundGradient = 'bg-gradient-to-r from-primary to-purple-700';
    }
    
    const displayProperties = 'relative group';

    const attributes = {};
    if (id) attributes.id = id;
    type ? (attributes.type = type) : (attributes.type = 'submit');

    return (
        <div
            className={`${backgroundGradient} inline-block shadow-md hover:shadow-sm ${
                minWidth ? `min-w-[${minWidth}px]` : ''
            } gap-5 ${textProperties} ${padding} ${animationProperties} ${displayProperties} ${borderRadius} ${border} ${fontSize} ${cursorStyle} ${className}`}
        >
            {isLoading && (
                <div className="absolute top-0 left-[10%] flex items-center justify-center h-full">
                    <LoadingSpinner />
                </div>
            )}
            <button
                {...attributes}
                onClick={onClickHandler}
                className={`absolute top-0 left-0 h-full w-full ${borderRadius} ${animationProperties} ${buttonClasses} z-10`}
                aria-label={label || null}
                aria-busy={isLoading ? 'true' : null}
                aria-disabled={isLoading ? 'true' : null}
                disabled={isLoading}
            ></button>
            <span
                className={`relative z-20 pointer-events-none transition ease-linear ${
                    isLoading ? 'ml-4' : ''
                }`}
            >
                {!isLoading ? label : 'Processing...'}
            </span>
        </div>
    );
}
