import React from 'react';
import { Link } from 'gatsby';
import SiteSocials from '../molecules/SiteSocials';
import FlockxByFetchLogo from '../atoms/icons/flockxByFetch';

const footerNavigation = [
  { label: 'Contact Us', id: 'intercom-contact-us' },
  { label: 'Business', url: '/business' },
  { label: 'Cookies', url: '/legal/cookies' },
  { label: 'Privacy', url: '/legal/privacy' },
  { label: 'Terms', url: '/legal/terms' },
];

function Footer({ footerHero }) {
  const linkStyles = 'text-sm hover:underline text-grey';
  const currentYear = new Date().getFullYear();

  return (
    <div>
      {footerHero && footerHero()}
      <footer
        className='bg-transparent relative z-20 bg-white py-6 px-4 sm:px-6 lg:py-6 lg:px-8'
        aria-labelledby='footer-heading'
      >
        <div className='max-w-7xl mx-auto '>
          <div
            className='grid gap-10 lg:gap-2 lg:flex justify-center items-center lg:justify-between w-full grid-flow-row  '>
            <div className='flex justify-center relative right-2 sm:right-0'>
            <Link to="/">
                <FlockxByFetchLogo className="h-12 w-auto logo-grey light-version" />
              </Link>
            </div>
            <div className='flex flex-col items-center justify-between'>
              <div
                className='grid w-full items-center justify-center grid-cols-3 md:grid-cols-none md:max-w-lg lg:max-w-3xl md:auto-cols-auto md:grid-flow-col gap-6'>
                {footerNavigation.map((item, index) => {
                  if (item.placeholder)
                    return (
                      <div
                        key={index}
                        className='block md:hidden'
                      ></div>
                    );
                  if (item.externalLink) {
                    return (
                      <a
                        key={index}
                        href={item.url}
                        className={linkStyles}
                      >
                        {item.label}
                      </a>
                    );
                  }
                  if (item.url) {
                    return (
                      <Link
                        to={item.url}
                        className={linkStyles}
                        key={index}
                      >
                        {item.label}
                      </Link>
                    );
                  }
                  return (
                    <button
                      type='button'
                      id={item.id}
                      key={index}
                      className='text-sm hover:pointer hover:underline text-grey'
                    >
                      {item.label}
                    </button>
                  );
                })}
              </div>
            </div>
            <SiteSocials />
          </div>
        </div>
        <p className='text-sm text-center mt-6 text-grey'>
          &#169; {currentYear}{' '}
          <span className='text-default'>flockx</span>{' '}
        </p>
      </footer>
      {process.env.NODE_ENV !== 'development' && (
        <noscript
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_GOOGLE_TAG_MANAGER_MEASUREMENT_ID}"
                        height="0" width="0" style="display:none;visibility:hidden"></iframe>`
          }}
        />
      )}
    </div>
  );
}

export default Footer;
